<template>
  <v-card class="elevation-0">
    <v-row class="mr-3">
      <v-col>
        <v-autocomplete v-model="selectedAdNetworks" :items="adNetworks" multiple label="Ad networks" clearable prepend-icon="stream">
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field v-model="accountNameSearch" label="Account name" clearable prepend-icon="account_balance">
        </v-text-field>
      </v-col>
      <v-col>
        <v-autocomplete v-model="selectedActions" :items="actions" multiple label="Actions" clearable prepend-icon="build">
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field v-model="keywordSearch" label="Keyword" clearable prepend-icon="text_snippet">
        </v-text-field>
      </v-col>
    </v-row>

    <v-row class="mr-3 mb-3">
      <v-col>
      </v-col>
      <v-col>
      </v-col>
      <v-col>
      </v-col>
      <v-col>
        <v-btn class="mt-2" color="primary" v-on:click="show">Show</v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-0"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-icon>{{ nkw_getActionIcon(item.action) }}</v-icon>
      </template>

      <template v-slot:[`item.notated_keyword`]="{ item }">
        <router-link v-if="item.action === 'Add'" class="blue--text darken-1" :to="{ name: 'Negative keyword', params: {nkwID: item.nkw_keywords_id }}">
          {{item.notated_keyword}}
        </router-link>
        <span v-else>{{item.notated_keyword}}</span>
      </template>

      <template v-slot:[`item.keyword_type`]="{ item }">
        <v-icon>{{ nkw_getTypeIcon(item.keyword_type) }}</v-icon>
      </template>

      <template v-slot:[`item.entry_at`]="{ item }">
        {{ datatables_formatTimestamp(item.entry_at) }}
      </template>

      <template v-slot:[`item.synced_at`]="{ item }">
        <span v-if="item.is_synced">{{ datatables_formatTimestamp(item.synced_at) }}</span>
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'
import nkwMixin from '@/mixins/negativeKeywords'

export default {
  name: 'NKWSyncLogTable',

  props: {
    synced: { type: Boolean, required: true },
  },

  data () {
    return {
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      actions: ['Add', 'Remove'],
      selectedAdNetworks: [],
      accountNameSearch: '',
      selectedActions: [],
      keywordSearch: '',
      lsOptions: 'nkwSyncLog_options',
      lsFilters: 'nkwSyncLog_filters',
      dataEndpoint: '/a/nkw/sync-log',
    }
  },

  mixins: [dataTablesMixin, nkwMixin],

  computed: {
    adNetworks () {
      return this.$store.state.core.adNetworks
    },
    headers () {
      var headerAr = [
        { text: 'Network', value: 'ad_network' },
        { text: 'Account ID', value: 'account_id' },
        { text: 'Account name', value: 'account_name' },
        { text: 'Action', value: 'action' },
        { text: 'Keyword', value: 'notated_keyword' },
        { text: 'Type', value: 'keyword_type' },
        { text: 'Entry at', value: 'entry_at' },
      ]

      if (this.synced) {
        headerAr.push({ text: 'Synced at', value: 'synced_at' })
      }
      return headerAr
    }
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    fetchData: function () {
      let myURL = this.dataEndpoint + '?xfields='
      this.loading = true

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, [], ['id', 'nkw_keywords_id', 'is_synced'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // filter synced
      myURL += '&is_synced=' + this.synced

      // filter ad networks
      if (this.selectedAdNetworks.length > 0) {
        myURL += '&ad_network=' + this.selectedAdNetworks.join('|')
      }

      // filter account name search
      if (this.accountNameSearch) {
        myURL += '&account_name=~' + this.accountNameSearch + '~'
      }

      // filter actions
      if (this.selectedActions.length > 0) {
        myURL += '&action=' + this.selectedActions.join('|')
      }

      // filter keyword search
      if (this.keywordSearch) {
        myURL += '&keyword=~' + this.keywordSearch + '~'
      }

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    show: function () {
     let filterObj = {
        'selectedAdNetworks': this.selectedAdNetworks,
        'accountNameSearch': this.accountNameSearch,
        'selectedActions': this.selectedActions,
        'keywordSearch': this.keywordSearch
      }

      localStorage.setItem(this.lsFilters, JSON.stringify(filterObj))

      this.options.page = 1;
      this.fetchData()
    },
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }

    // if present, use local filters
    if (localStorage.getItem(this.lsFilters)) {
      let filters = JSON.parse(localStorage.getItem(this.lsFilters))
      if (filters['selectedAdNetworks']) { this.selectedAdNetworks = filters['selectedAdNetworks'] }
      if (filters['accountNameSearch']) { this.accountNameSearch = filters['accountNameSearch'] }
      if (filters['selectedActions']) { this.selectedActions = filters['selectedActions'] }
      if (filters['keywordSearch']) { this.keywordSearch = filters['keywordSearch'] }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>

<style scoped>
</style>
